import React, { FC, useState, useEffect, useContext } from "react"
import Link from "next/link"
import { UserNav } from "@components/common"
import env from "@config/env"
import { BuilderComponent, builder } from "@builder.io/react"
import { useCart } from "@lib/swell/storefront-data-hooks"
import { useThemeUI } from "theme-ui"
import { useUI } from "@components/ui/context"
import Image from "next/image"
import Searchbar from "./Searchbar"
import { useRouter } from "next/router"
import { sidebarContext } from "@components/common/Layout"

import { Fragment } from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { Bars3Icon, XMarkIcon, ShoppingCartIcon } from "@heroicons/react/24/outline"
import { MobileSearch, Search, MinimalSearch } from "@components/AlgoliaSearch"

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react"
import { MagnifyingGlassIcon, ShoppingBagIcon, UserIcon } from "@heroicons/react/24/outline"

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ")
}
const ShoppingCartButton = () => {
  const cart = useCart()
  const { setOpenSidebar } = useContext(sidebarContext)

  return (
    <div className="flex flex-1 items-center justify-end">
      <button
        onClick={(e) => setOpenSidebar(true)}
        className="group -m-2 flex items-center rounded-md p-2 text-gray-800"
      >
        <ShoppingCartIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
        <span className="ml-2 text-sm font-medium">{cart?.items?.length}</span>
        <span className="sr-only">items in cart, view bag</span>
      </button>
    </div>
  )
}

const Navbar: FC = () => {
  const [announcement, setAnnouncement] = useState()
  const { theme } = useThemeUI()
  const { navigationLinks, logo, navBar } = useUI()
  const cart = useCart()
  const router = useRouter()
  const [open, setOpen] = useState(false)

  function active(link: string) {
    if (link === router.asPath) return true
    return false
  }

  useEffect(() => {
    let isSubscribed = true
    async function fetchContent() {
      const items = cart?.items || []
      const anouncementContent = await builder
        .get("announcement-bar", {
          cachebust: env.isDev,
          userAttributes: {
            itemInCart: items.map((item: any) => item.product.slug),
          } as any,
        })
        .toPromise()
      isSubscribed ? setAnnouncement(anouncementContent) : null
    }

    fetchContent()
    // cancel subscription to useEffect
    return () => {
      isSubscribed = false
    }
  }, [cart?.items])

  return (
    <React.Fragment>
      <div className="fixed top-4 right-0 left-0 z-30">
        {/* Mobile Menu */}
        <Dialog open={open} onClose={setOpen} className="relative z-40 lg:hidden">
          <DialogBackdrop
            transition
            className="bg-opacity-25 fixed inset-0 bg-black transition-opacity duration-300 ease-linear data-closed:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative flex w-full transform flex-col overflow-y-auto bg-white pb-12 shadow-xl transition duration-300 ease-in-out data-closed:-translate-x-full"
            >
              {/* Mobile - Top Bar */}
              <div className="flex px-4 pt-5">
                <div className="flex flex-1 items-center justify-start">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="-m-2 inline-flex rounded-md p-2 text-gray-400"
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 stroke-black" />
                  </button>
                </div>
                <div className="flex flex-1 items-center justify-center">
                  {navBar && navBar.logo && (
                    <Link href="/">
                      <img className="h-12 w-auto" src={navBar.logo} alt="New Way Van Life" />
                    </Link>
                  )}
                </div>

                <div className="flex flex-1 items-center justify-end">
                  <div className="flex space-x-2">
                    <MinimalSearch />
                    <ShoppingCartButton />
                  </div>
                </div>
              </div>

              <TabGroup className="flex-1 overflow-y-auto bg-gradient-to-b from-transparent from-10% via-transparent via-30% to-slate-100 to-90%">
                {/* Mobile Menu - Categories */}
                <div>
                  <TabList className="flex px-4">
                    {navBar.categories.map((category: any) => (
                      <Tab
                        key={category.name}
                        className="data-selected:border-primary data-selected:text-primary my-3 flex-1 border-b border-gray-200 px-1 pb-4 text-sm font-semibold whitespace-nowrap text-gray-500 data-selected:border-b-2"
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </TabList>
                </div>
                <TabPanels as={Fragment}>
                  {navBar.categories.map((category: any) => (
                    <TabPanel key={category.name} className="mt-4 space-y-5 px-4">
                      {/* Mobile Menu - Sections */}
                      {category.sections.map((section: any, columnIdx: number) => (
                        <div key={columnIdx}>
                          <div key={section.name}>
                            <div
                              id={`${category.id}-${section.id}-heading-mobile`}
                              className="font-mono text-sm text-gray-900"
                            >
                              {section.name}
                            </div>
                            <ul
                              role="list"
                              aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                              className="mt-2 flex flex-col space-y-2"
                            >
                              {section.items.map((item: any) => (
                                <li key={item.name} className="flow-root">
                                  <a href={item.href} className="-m-2 block p-2 text-2xl">
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}

                      <hr />

                      {/* Mobile Menu - Links */}
                      <div>
                        {category.links.map((item: any, itemIdx: number) => (
                          <div key={itemIdx} className="">
                            <a href={item.link} className="-m-2 block p-2 text-sm text-gray-500">
                              {item.text}
                            </a>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                  ))}
                </TabPanels>
              </TabGroup>

              {/* Sticky Footer */}
              <div className="sticky bottom-0 border-t border-gray-200 bg-white px-4 py-6 shadow-md">
                <div className="flex items-center justify-between">
                  <Link
                    href="/configure/sprinter-van-builder-144"
                    className="items-center gap-x-1.5 rounded-full bg-black px-6 py-4 text-xs font-semibold text-white sm:hidden"
                  >
                    Build Your Van
                  </Link>
                  <div className="flex gap-2">
                    <a
                      href="https://www.instagram.com/newwayvanlife/?hl=en"
                      target="_blank"
                      className="rounded-full border-2 border-black p-2"
                    >
                      <span className="font-mono text-black">IG</span>
                    </a>
                    <a
                      href="https://www.youtube.com/@NewWayVanLife"
                      target="_blank"
                      className="rounded-full border-2 border-black p-2"
                    >
                      <span className="font-mono text-black">YT</span>
                    </a>
                    <a
                      href="https://www.facebook.com/NewWayVanLife/"
                      target="_blank"
                      className="rounded-full border-2 border-black p-2"
                    >
                      <span className="font-mono text-black">FB</span>
                    </a>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        <header className={`relative mx-4 rounded-lg border border-gray-200 bg-white shadow-md`}>
          <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="">
              <div className="flex h-16 items-center justify-between">
                {/* Mobile Menu - Open Menu Button */}
                <div className="flex flex-1 items-center lg:hidden">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                  >
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon aria-hidden="true" className="h-6 w-6 stroke-black" />
                  </button>
                </div>

                {/* Flyout menus */}
                <PopoverGroup className="hidden lg:block lg:flex-1 lg:self-stretch">
                  <div className="flex h-full space-x-8">
                    {navBar.categories.map((category: any) => (
                      <Popover key={category.name} className="flex">
                        {({ open }) => {
                          return (
                            <>
                              <div className="relative flex">
                                <PopoverButton className="group relative z-10 my-3 flex items-center justify-center rounded-full p-4 text-sm font-semibold text-gray-900 transition-colors duration-200 ease-out hover:bg-black hover:text-white data-open:bg-black data-open:text-white">
                                  {category.name}
                                </PopoverButton>
                              </div>
                              <PopoverPanel
                                transition
                                className="shadow-b absolute inset-x-0 top-full z-20 max-h-[80vh] overflow-y-auto rounded-b-lg border border-gray-200 transition data-closed:opacity-0 data-enter:duration-200 data-enter:ease-out data-leave:duration-150 data-leave:ease-in"
                              >
                                {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                <div
                                  aria-hidden="true"
                                  className="absolute inset-0 top-1/2 rounded-b-lg bg-white shadow-2xl"
                                />

                                <div className="relative rounded-b-lg bg-white">
                                  <div className="mx-auto max-w-7xl px-8">
                                    <div className="grid grid-cols-7 gap-x-8 py-16">
                                      {/* Sections */}
                                      <div className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-sm text-gray-500">
                                        {category.sections.map(
                                          (section: any, columnIdx: number) => (
                                            <div key={columnIdx} className="space-y-10">
                                              <div key={section.name}>
                                                <p
                                                  id={`${category.id}-${section.id}-heading`}
                                                  className="font-mono font-medium text-gray-900"
                                                >
                                                  {section.name}
                                                </p>
                                                <ul
                                                  role="list"
                                                  aria-labelledby={`${category.id}-${section.id}-heading`}
                                                  className="mt-4 space-y-4"
                                                >
                                                  {section.items.map((item: any) => (
                                                    <li key={item.name} className="flex">
                                                      <a
                                                        href={item.href}
                                                        className="text-2xl text-gray-900 hover:text-gray-500"
                                                      >
                                                        {item.name}
                                                      </a>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </div>
                                          ),
                                        )}

                                        <hr />

                                        {/* Links */}
                                        <div>
                                          {category.links.map((item: any, itemIdx: number) => (
                                            <div key={itemIdx}>
                                              <a
                                                href={item.link}
                                                className="-m-2 block p-2 text-gray-500"
                                              >
                                                {item.text}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>

                                      {/* Desktop Menu - Featured Tiles */}
                                      <div className="col-span-5 grid h-fit grid-cols-6 gap-4 text-sm">
                                        {category.featured.map((item: any, itemIdx: number) => (
                                          <div
                                            key={item.name}
                                            className={classNames(
                                              itemIdx === 0 || itemIdx === 1
                                                ? "col-span-3 aspect-3/2"
                                                : "col-span-2 aspect-square",
                                              "group rounded-3xl",
                                            )}
                                          >
                                            <div className="relative h-full">
                                              <img
                                                alt={item.imageAlt}
                                                src={item.image}
                                                className={classNames(
                                                  itemIdx === 0 || itemIdx === 1
                                                    ? "aspect-3/2"
                                                    : "aspect-square",
                                                  "h-full w-full rounded-3xl object-cover group-hover:opacity-75",
                                                )}
                                              />
                                              <div className="absolute inset-0 flex flex-col p-6">
                                                <div className="font-mono text-sm tracking-wide text-gray-900 uppercase">
                                                  {item.subtitle}
                                                </div>
                                                <h3 className="text-3xl font-medium text-gray-900">
                                                  {item.name}
                                                </h3>
                                                <div className="mt-auto">
                                                  <a
                                                    href={item.href}
                                                    className="inline-flex items-center justify-center rounded-full bg-black px-6 py-2 text-sm font-semibold text-white hover:bg-gray-900"
                                                  >
                                                    <span
                                                      aria-hidden="true"
                                                      className="absolute inset-0"
                                                    />
                                                    {item.linkText}
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                        <div
                                          className={classNames(
                                            "col-span-2 aspect-square rounded-3xl bg-black",
                                          )}
                                        >
                                          <div className="relative">
                                            <div className="absolute inset-0 flex aspect-square flex-col p-6">
                                              <div className="font-mono text-sm tracking-wide text-white uppercase">
                                                Get In Touch
                                              </div>
                                              <h3 className="text-2xl font-medium text-white xl:text-4xl">
                                                {"Let's Connect"}
                                              </h3>
                                              <div className="mt-4 space-y-2">
                                                <p className="text-lg text-white xl:text-xl">
                                                  858-522-0162
                                                </p>
                                                <a
                                                  href="mailto:hello@newwayvan.life"
                                                  className="text-lg text-white xl:text-xl"
                                                >
                                                  hello@newwayvan.life
                                                </a>
                                              </div>
                                              <div className="mt-2 mb-2 flex gap-4">
                                                <a
                                                  href="https://www.instagram.com/newwayvanlife/?hl=en"
                                                  target="_blank"
                                                  className="rounded-full bg-white p-4"
                                                >
                                                  <span className="font-mono text-black">IG</span>
                                                </a>
                                                <a
                                                  href="https://www.youtube.com/@NewWayVanLife"
                                                  target="_blank"
                                                  className="rounded-full bg-white p-4"
                                                >
                                                  <span className="font-mono text-black">YT</span>
                                                </a>
                                                <a
                                                  href="https://www.facebook.com/NewWayVanLife/"
                                                  target="_blank"
                                                  className="rounded-full bg-white p-4"
                                                >
                                                  <span className="font-mono text-black">FB</span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </PopoverPanel>
                            </>
                          )
                        }}
                      </Popover>
                    ))}
                  </div>
                </PopoverGroup>

                {/* Logo */}
                {navBar && navBar.logo && (
                  <Link href="/">
                    <img className="h-12 w-auto" src={navBar.logo} alt="New Way Van Life" />
                  </Link>
                )}

                {/* Search + Shopping Cart */}
                <div className="flex flex-1 items-center justify-end">
                  <div className="flex items-center justify-end space-x-4">
                    <Link
                      href="/configure/sprinter-van-builder-144"
                      className="hidden items-center gap-x-1.5 rounded-full bg-black px-4 py-2 text-xs font-semibold text-white sm:inline-flex sm:text-sm"
                    >
                      Build Your Van
                    </Link>
                    <div className="hidden lg:block">
                      <MinimalSearch />
                    </div>

                    {/* <SearchDialog className="hidden lg:block" {...dialogProps} /> */}
                    <div className="hidden lg:block">
                      <ShoppingCartButton />
                    </div>
                  </div>

                  <div className="flex space-x-2 lg:hidden">
                    <div className="ml-2 p-2 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Search</span>
                      <MinimalSearch />
                    </div>

                    {/* Mobile menu button */}
                    <ShoppingCartButton />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </React.Fragment>
  )
}

export default Navbar
