import { forwardRef, Fragment, useEffect, useRef, useState } from "react"
// @ts-ignore
import { v4 as uuidv4 } from "uuid"
import { useRouter } from "next/router"
import { createAutocomplete } from "@algolia/autocomplete-core"
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia"
import { Dialog, Transition } from "@headlessui/react"
import algoliasearch from "algoliasearch/lite"
import clsx from "clsx"
import Image from "next/legacy/image"
import { getPrice } from "@lib/swell/storefront-data-hooks/src/utils/product"
import { DetailedHTMLProps, InputHTMLAttributes, ButtonHTMLAttributes } from "react"

const searchClient = algoliasearch(
  // @ts-ignore
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
)

function useAutocomplete() {
  let id = uuidv4()
  let router = useRouter()
  let [autocompleteState, setAutocompleteState] = useState<any>({})

  let [autocomplete] = useState(() =>
    createAutocomplete({
      id,
      placeholder: "Find something...",
      defaultActiveItemId: 0,
      onStateChange({ state }) {
        setAutocompleteState(state)
      },
      shouldPanelOpen({ state }) {
        return state.query !== ""
      },
      navigator: {
        navigate({ itemUrl }) {
          autocomplete.setIsOpen(true)
          router.push(itemUrl)
        },
      },
      getSources() {
        return [
          {
            sourceId: "documentation",
            getItemInputValue({ item }: { item: any }) {
              return item.query?.toString()
            },
            getItemUrl({ item }: { item: any }) {
              switch (item.__autocomplete_indexName) {
                case "builder-blog-post":
                  return `/blog/${item.data.handle}`
                case "products":
                  return `/product/${item.slug}`
              }
            },
            onSelect({ itemUrl }) {
              // @ts-ignore
              router.push(itemUrl)
            },
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    query,
                    indexName: "products",
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: '<mark class="underline bg-transparent text-emerald-500">',
                      highlightPostTag: "</mark>",
                    },
                  },
                  {
                    query,
                    indexName: "builder-blog-post",
                    params: {
                      hitsPerPage: 2,
                      highlightPreTag: '<mark class="underline bg-transparent text-emerald-500">',
                      highlightPostTag: "</mark>",
                      filters: "published:published",
                    },
                  },
                ],
              })
            },
          },
        ]
      },
    })
  )

  return { autocomplete, autocompleteState }
}

function SearchIcon(props: any) {
  return (
    <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.01 12a4.25 4.25 0 1 0-6.02-6 4.25 4.25 0 0 0 6.02 6Zm0 0 3.24 3.25"
      />
    </svg>
  )
}

function NoResultsIcon(props: any) {
  return (
    <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.01 12a4.237 4.237 0 0 0 1.24-3c0-.62-.132-1.207-.37-1.738M12.01 12A4.237 4.237 0 0 1 9 13.25c-.635 0-1.237-.14-1.777-.388M12.01 12l3.24 3.25m-3.715-9.661a4.25 4.25 0 0 0-5.975 5.908M4.5 15.5l11-11"
      />
    </svg>
  )
}

function LoadingIcon(props: any) {
  let id = uuidv4()

  return (
    <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" {...props}>
      <circle cx="10" cy="10" r="5.5" strokeLinejoin="round" />
      <path
        stroke={`url(#${id})`}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.5 10a5.5 5.5 0 1 0-5.5 5.5"
      />
      <defs>
        <linearGradient id={id} x1="13" x2="9.5" y1="9" y2="15" gradientUnits="userSpaceOnUse">
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
interface ProductSearchResultProps {
  result: any
  resultIndex: number
  autocomplete: any
  collection: any
}

function ProductSearchResult({
  result,
  resultIndex,
  autocomplete,
  collection,
}: ProductSearchResultProps) {
  let id = uuidv4()

  return (
    <li
      className={clsx(
        "group flex cursor-default items-center justify-between space-x-6 px-4 py-6 aria-selected:bg-zinc-800/50",
        resultIndex > 0 && "border-t border-zinc-800"
      )}
      aria-labelledby={`${id}-hierarchy ${id}-title`}
      {...autocomplete.getItemProps({
        item: result,
        source: collection.source,
      })}
    >
      <div
        id={`${id}-title`}
        aria-hidden="true"
        className="flex space-x-6 space-y-1 text-sm font-medium text-white group-aria-selected:text-emerald-500"
      >
        {result.images && result.images.length > 0 && (
          <Image
            src={result.images[0].file.url}
            alt={"Product image"}
            width={50}
            height={50}
            className="h-5 w-5 flex-none rounded-md bg-gray-100 object-cover object-center"
          />
        )}

        <span>{result.name}</span>
      </div>
      <p className="font-medium text-white group-aria-selected:text-emerald-500">
        {getPrice(result.price, "USD")}
      </p>
    </li>
  )
}
interface BlogSearchResultProps {
  result: any
  resultIndex: number
  autocomplete: any
  collection: any
}

function BlogSearchResult({
  result,
  resultIndex,
  autocomplete,
  collection,
}: BlogSearchResultProps) {
  let id = uuidv4()

  return (
    <li
      className={clsx(
        "group flex cursor-default items-center justify-between space-x-6 px-4 py-6 aria-selected:bg-zinc-800/50",
        resultIndex > 0 && "border-t border-zinc-800"
      )}
      aria-labelledby={`${id}-hierarchy ${id}-title`}
      {...autocomplete.getItemProps({
        item: result,
        source: collection.source,
      })}
    >
      <div
        id={`${id}-title`}
        aria-hidden="true"
        className="flex space-x-6 space-y-1 text-sm font-medium text-white group-aria-selected:text-emerald-500"
      >
        {result.data.image && (
          <Image
            src={result.data.image}
            alt={"Product image"}
            width={50}
            height={50}
            className="h-5 w-5 flex-none rounded-md bg-gray-100 object-cover object-center"
          />
        )}
        <div className="flex flex-col">
          <span>{result.data.title}</span>
          <span className="text-xs font-normal">{result.data.blurb}</span>
        </div>
      </div>
      <p className="font-medium text-white group-aria-selected:text-emerald-500">Blog</p>
    </li>
  )
}

interface SearchResultsProps {
  autocomplete: any
  query: string
  collection: any
}

function SearchResults({ autocomplete, query, collection }: SearchResultsProps) {
  if (collection.items.length === 0) {
    return (
      <div className="p-6 text-center">
        <NoResultsIcon className="mx-auto h-5 w-5 stroke-zinc-600" />
        <p className="mt-2 text-xs text-zinc-400">
          Nothing found for{" "}
          <strong className="break-words font-semibold text-white">&lsquo;{query}&rsquo;</strong>.
          Please try again.
        </p>
      </div>
    )
  }

  return (
    <ul role="list" {...autocomplete.getListProps()}>
      {collection.items.map((result: any, resultIndex: number) => {
        switch (result.__autocomplete_indexName) {
          case "products":
            return (
              <ProductSearchResult
                key={result.objectID}
                result={result}
                resultIndex={resultIndex}
                autocomplete={autocomplete}
                collection={collection}
              />
            )
          case "builder-blog-post":
            return (
              <BlogSearchResult
                key={result.objectID}
                result={result}
                resultIndex={resultIndex}
                autocomplete={autocomplete}
                collection={collection}
              />
            )
        }
      })}
    </ul>
  )
}

interface SearchInputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  autocomplete: any
  autocompleteState: any
  onClose: () => void
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(
  { autocomplete, autocompleteState, onClose },
  inputRef
) {
  let inputProps = autocomplete.getInputProps({})

  return (
    <div className="group relative flex h-12">
      <SearchIcon className="pointer-events-none absolute left-3 top-0 h-full w-5 stroke-zinc-500" />
      <input
        ref={inputRef}
        className={clsx(
          "flex-auto appearance-none bg-transparent pl-10 text-white outline-hidden placeholder:text-zinc-500 focus:w-full focus:flex-none sm:text-sm [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden [&::-webkit-search-results-button]:hidden [&::-webkit-search-results-decoration]:hidden",
          autocompleteState.status === "stalled" ? "pr-11" : "pr-4"
        )}
        {...inputProps}
        onKeyDown={(event) => {
          if (
            event.key === "Escape" &&
            !autocompleteState.isOpen &&
            autocompleteState.query === ""
          ) {
            // In Safari, closing the dialog with the escape key can sometimes cause the scroll position to jump to the
            // bottom of the page. This is a workaround for that until we can figure out a proper fix in Headless UI.
            // @ts-ignore
            document.activeElement?.blur()

            onClose()
          } else {
            inputProps.onKeyDown(event)
          }
        }}
      />
      {autocompleteState.status === "stalled" && (
        <div className="absolute inset-y-0 right-3 flex items-center">
          <LoadingIcon className="h-5 w-5 animate-spin  stroke-zinc-800 text-emerald-400" />
        </div>
      )}
    </div>
  )
})

interface SearchDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  className?: string
}

function SearchDialog({ open, setOpen, className }: SearchDialogProps) {
  let router = useRouter()
  let formRef = useRef<HTMLFormElement>(null)
  let panelRef = useRef<HTMLDivElement | null>(null)
  let inputRef = useRef<HTMLInputElement | null>(null)
  let { autocomplete, autocompleteState } = useAutocomplete()

  useEffect(() => {
    if (!open) {
      return
    }

    function onRouteChange() {
      setOpen(false)
    }

    router.events.on("routeChangeStart", onRouteChange)
    router.events.on("hashChangeStart", onRouteChange)

    return () => {
      router.events.off("routeChangeStart", onRouteChange)
      router.events.off("hashChangeStart", onRouteChange)
    }
  }, [open, setOpen, router])

  useEffect(() => {
    if (open) {
      return
    }

    function onKeyDown(event: any) {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        setOpen(true)
      }
    }

    window.addEventListener("keydown", onKeyDown)

    return () => {
      window.removeEventListener("keydown", onKeyDown)
    }
  }, [open, setOpen])

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => autocomplete.setQuery("")}>
      <Dialog onClose={setOpen} className={clsx("fixed inset-0 z-50", className)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/40 backdrop-blur-xs" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto px-4 py-4 sm:px-6 sm:py-20 md:py-32 lg:px-8 lg:py-[15vh]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto overflow-hidden rounded-lg bg-zinc-900 shadow-xl ring-1 ring-zinc-800 sm:max-w-xl">
              <div {...autocomplete.getRootProps({})}>
                {/* @ts-ignore */}
                <form
                  ref={formRef}
                  {...autocomplete.getFormProps({
                    inputElement: inputRef.current,
                  })}
                >
                  <SearchInput
                    ref={inputRef}
                    autocomplete={autocomplete}
                    autocompleteState={autocompleteState}
                    onClose={() => setOpen(false)}
                  />
                  {/* @ts-ignore */}
                  <div
                    ref={panelRef}
                    className="bg-white/2.5 border-t border-zinc-100/5 empty:hidden"
                    {...autocomplete.getPanelProps({})}
                  >
                    {autocompleteState.isOpen && (
                      <>
                        <SearchResults
                          autocomplete={autocomplete}
                          query={autocompleteState.query}
                          collection={autocompleteState.collections[0]}
                        />
                      </>
                    )}
                  </div>
                </form>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

interface SearchProps {
  buttonProps: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  dialogProps: SearchDialogProps
}

function useSearchProps(): SearchProps {
  let buttonRef = useRef<HTMLButtonElement | null>(null)
  let [open, setOpen] = useState(false)

  return {
    buttonProps: {
      ref: buttonRef,
      onClick() {
        setOpen(true)
      },
    },
    dialogProps: {
      open,
      setOpen(open) {
        if (buttonRef.current) {
          let { width, height } = buttonRef.current.getBoundingClientRect()
          if (!open || (width !== 0 && height !== 0)) {
            setOpen(open)
          }
        }
      },
    },
  }
}

export function Search() {
  let [modifierKey, setModifierKey] = useState<string | undefined>()
  let { buttonProps, dialogProps } = useSearchProps()

  useEffect(() => {
    setModifierKey(/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? "⌘" : "Ctrl ")
  }, [])

  return (
    <div className="hidden lg:block lg:max-w-md lg:flex-auto">
      <button
        type="button"
        className="hidden h-8 w-full items-center gap-2 rounded-full bg-white/5 pl-2 pr-3 text-sm text-zinc-400 ring-1 ring-inset ring-white/10  transition hover:ring-white/20  lg:flex focus:not-focus-visible:outline-hidden"
        {...buttonProps}
      >
        <SearchIcon className="h-5 w-5 stroke-current" />
        Find something...
        <kbd className="text-2xs ml-auto text-zinc-500">
          <kbd className="font-sans">{modifierKey}</kbd>
          <kbd className="font-sans">K</kbd>
        </kbd>
      </button>
      <SearchDialog className="hidden lg:block" {...dialogProps} />
    </div>
  )
}

export function MinimalSearch() {
  let { buttonProps, dialogProps } = useSearchProps()

  return (
    <div className="lg:max-w-md lg:flex-auto">
      <button
        type="button"
        className="flex h-6 w-6 items-center justify-center rounded-md transition hover:bg-white/5 focus:not-focus-visible:outline-hidden"
        {...buttonProps}
      >
        <SearchIcon className="h-6 w-6 stroke-black" />
      </button>
      <SearchDialog {...dialogProps} />
    </div>
  )
}

export function MobileSearch() {
  let { buttonProps, dialogProps } = useSearchProps()

  return (
    <div className="contents lg:hidden">
      <button
        type="button"
        className="flex h-6 w-6 items-center justify-center rounded-md transition hover:bg-white/5 lg:hidden focus:not-focus-visible:outline-hidden"
        aria-label="Find something..."
        {...buttonProps}
      >
        <SearchIcon className="h-6 w-6 stroke-black" />
      </button>
      <SearchDialog className="lg:hidden" {...dialogProps} />
    </div>
  )
}
