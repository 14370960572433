import Link from "next/link"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { useCart } from "@lib/swell/storefront-data-hooks/src/hooks/useCart"
import { useRemoveItemFromCart } from "@lib/swell/storefront-data-hooks/src/hooks/useRemoveItemFromCart"
import { useUpdateItemQuantity } from "@lib/swell/storefront-data-hooks/src/hooks/useUpdateItemQuantity"
import { getPrice } from "@lib/swell/storefront-data-hooks/src/utils/product"
import { OptionValue } from "@lib/swell/storefront-data-hooks/src/types"
import { useCheckoutUrl } from "@lib/swell/storefront-data-hooks/src/hooks/useCheckoutUrl"
import { Table, TableBody, TableCell, TableRow } from "@components/ui/Table"

export default function FullCart() {
  const checkoutUrl = useCheckoutUrl()
  const cart = useCart()
  const removeItem = useRemoveItemFromCart()
  const updateItem = useUpdateItemQuantity()
  const items = cart?.items ?? []
  const total = getPrice(cart?.grand_total, cart?.currency ?? "USD")

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8 mt-10 sm:mt-16">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Shopping Cart
        </h1>
        <form className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <h2 id="cart-heading" className="sr-only">
              Items in your shopping cart
            </h2>

            <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
              {items.map((item, productIdx) => (
                <li key={item.id} className="flex py-6 sm:py-10">
                  <div className="shrink-0">
                    <img
                      src={item.product.images?.[0]?.file.url}
                      alt={item.product.images?.[0]?.file.metadata}
                      className="size-24 rounded-md object-cover sm:size-48"
                    />
                  </div>

                  <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                    <div className="relative pr-9 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:pr-0">
                      <div className="col-span-3">
                        <div className="flex justify-between">
                          <h3 className="text-sm">
                            <Link
                              href={`/product/${item.product.slug}?itemId=${item.id}`}
                              className="font-medium text-gray-700 hover:text-gray-800"
                            >
                              {item.product.name}
                            </Link>
                          </h3>
                        </div>
                        <div className="mt-4">
                          <Table>
                            <TableBody>
                              {item?.options.map((option: OptionValue) => (
                                <TableRow
                                  key={option.id}
                                  className="*:border-border hover:bg-transparent [&>:not(:last-child)]:border-r"
                                >
                                  <TableCell className="py-1 text-xs font-medium">
                                    {option.name}
                                  </TableCell>
                                  <TableCell className="py-1 text-xs text-gray-500">
                                    {option.value}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                        <p className="mt-4 text-sm font-medium text-gray-900 font-mono">
                          {item.product.sale ? (
                            <div>
                              <p className=" font-medium text-gray-900 line-through">
                                {getPrice(item.product.price, "USD")}
                              </p>
                              <div className="flex space-x-2">
                                <p className="font-medium text-base text-red-600">
                                  {getPrice(item.orig_price, "USD")}
                                </p>
                                <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                                  SAVE {getPrice(item.product.price - item.orig_price, "USD")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <p className="text-base font-medium text-gray-900">
                              {getPrice(item.product.price, "USD")}
                            </p>
                          )}
                          {item.product.content.deposit && (
                            <p>
                              Deposit due today: {getPrice(item.price, cart?.currency ?? "USD")}
                            </p>
                          )}
                        </p>
                      </div>

                      <div className="mt-4 sm:mt-0 sm:pr-9">
                        {item.product.content.can_buy_multiple && (
                          <div className="grid w-full max-w-16 grid-cols-1">
                            <select
                              name={`quantity-${productIdx}`}
                              aria-label={`Quantity, ${item.product.name}`}
                              className="col-start-1 row-start-1 appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-primary-600 sm:text-sm/6"
                              onChange={(e) => updateItem(item.id, Number(e.target.value))}
                            >
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                            </select>
                          </div>
                        )}

                        <div className="absolute right-0 top-0">
                          <button
                            type="button"
                            className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                            onClick={(e) => updateItem(item.id, 0)}
                          >
                            <span className="sr-only">Remove</span>
                            <XMarkIcon aria-hidden="true" className="size-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
          >
            <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
              Order summary
            </h2>

            <dl className="mt-6 space-y-4">
              <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <dt className="text-base font-medium text-gray-900">Order total</dt>
                <dd className="text-base font-medium font-mono text-gray-900">{total}</dd>
              </div>
            </dl>

            <div className="mt-6">
              {checkoutUrl && (
                <Link
                  href={checkoutUrl}
                  className="flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-6 py-3 text-base font-medium text-white shadow-xs hover:bg-primary-700"
                >
                  Checkout
                </Link>
              )}
            </div>
          </section>
        </form>
      </div>
    </div>
  )
}
