"use client"

import { useState, useEffect } from "react"
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react"
import { ShoppingCartIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon } from "@heroicons/react/16/solid"
import {
  useCart,
  useCheckoutUrl,
  useRemoveItemFromCart,
  useUpdateItemQuantity,
} from "@lib/swell/storefront-data-hooks"
import { BuilderComponent, builder } from "@builder.io/react"
import env from "@config/env"
import { getPrice } from "@lib/swell/storefront-data-hooks/src/utils/product"
import Link from "next/link"

type CartSidebarViewProps = {
  openSidebar: boolean
  setOpenSidebar: any
}

export default function CartSidebarView({ openSidebar, setOpenSidebar }: CartSidebarViewProps) {
  const checkoutUrl = useCheckoutUrl()
  const cart = useCart()
  const removeItem = useRemoveItemFromCart()
  const updateItem = useUpdateItemQuantity()
  const [cartUpsell, setCartUpsell] = useState()

  const subTotal = getPrice(cart?.sub_total, cart?.currency ?? "USD")
  const total = getPrice(cart?.grand_total, cart?.currency ?? "USD")
  const shippingTotal = getPrice(cart?.shipment_total, cart?.currency ?? "USD")
  const taxTotal = getPrice(cart?.tax_total, cart?.currency ?? "USD")
  const items = cart?.items ?? []

  const updateQuantity = async (item: any, quantity: number) => {
    await updateItem(item.id, quantity)
  }

  useEffect(() => {
    async function fetchContent() {
      const items = cart?.items || []
      const cartUpsellContent = await builder
        .get("cart-upsell-sidebar", {
          cachebust: env.isDev,
          userAttributes: {
            itemInCart: items.map((item: any) => item.product?.slug),
          } as any,
        })
        .toPromise()
      setCartUpsell(cartUpsellContent)
    }
    fetchContent()
  }, [cart?.items])

  return (
    <Dialog open={openSidebar} onClose={setOpenSidebar} className="relative z-40">
      <DialogBackdrop
        transition
        className="hidden data-closed:opacity-0 data-enter:duration-300 data-leave:duration-200 data-enter:ease-out data-leave:ease-in sm:fixed sm:inset-0 sm:block sm:bg-gray-500/75 sm:transition-opacity"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-stretch justify-center text-center sm:items-center sm:px-6 lg:px-8">
          <DialogPanel
            transition
            className="flex w-full max-w-3xl transform text-left text-base transition data-closed:scale-105 data-closed:opacity-0 data-enter:duration-300 data-leave:duration-200 data-enter:ease-out data-leave:ease-in sm:my-8"
          >
            <div className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-8">
              <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium text-gray-900">Cart Quick View</h2>
                <button
                  type="button"
                  onClick={() => setOpenSidebar(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon aria-hidden="true" className="size-6" />
                </button>
              </div>

              <section aria-labelledby="cart-heading">
                <h2 id="cart-heading" className="sr-only">
                  Items in your shopping cart
                </h2>

                <ul role="list" className="divide-y divide-gray-200 px-4 sm:px-6 lg:px-8">
                  {items.map((item: any, itemIdx: number) => (
                    <li key={item.id} className="flex py-8 text-sm sm:items-center">
                      <img
                        src={item.product.images?.[0]?.file.url}
                        alt={item.product.images?.[0]?.file.metadata}
                        className="aspect-3/2 w-24 h-16 object-cover flex-none rounded-lg border border-gray-200 sm:h-24 sm:w-32"
                      />
                      <div className="ml-4 grid flex-auto grid-cols-1 grid-rows-1 items-start gap-x-5 gap-y-3 sm:ml-6 sm:grid-cols-4">
                        <div className="sm:row-end-1 flex-auto sm:pr-6">
                          <h3 className="font-medium text-gray-900">
                            <Link href={`/product/${item.product.slug}`}>{item.product.name}</Link>
                          </h3>
                        </div>
                        <p className="sm:row-span-2 sm:row-end-2 font-medium text-gray-900 sm:order-1 sm:ml-6 sm:col-span-2 sm:flex-none font-mono">
                          {item.product.sale ? (
                            <div>
                              <p className=" font-medium text-gray-900 line-through">
                                {getPrice(item.product.price, "USD")}
                              </p>
                              <div className="flex space-x-2">
                                <p className="font-medium text-base text-red-600">
                                  {getPrice(item.orig_price, "USD")}
                                </p>
                                <span className="hidden sm:inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                                  SAVE {getPrice(item.product.price - item.orig_price, "USD")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <p className="text-base font-medium text-gray-900">
                              {getPrice(item.product.price, "USD")}
                            </p>
                          )}
                          {item.product.content.deposit && (
                            <p className="mt-2 text-sm">
                              Deposit due today: {getPrice(item.price, cart?.currency ?? "USD")}
                            </p>
                          )}
                        </p>
                        <div className="flex justify-end items-center sm:flex-col sm:text-center">
                          {item.product.content.can_buy_multiple && (
                            <div className="inline-grid w-full max-w-16 grid-cols-1">
                              <select
                                value={item.quantity}
                                onChange={(e) => updateQuantity(item, parseInt(e.target.value))}
                                className="col-start-1 row-start-1 appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-primary-600 sm:text-sm/6"
                              >
                                {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                                  <option key={num} value={num}>
                                    {num}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}

                          <button
                            type="button"
                            onClick={() => updateQuantity(item, 0)}
                            className="ml-4 font-medium text-primary-600 hover:text-primary-500 sm:ml-0 sm:mt-2"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>

              <section aria-labelledby="summary-heading" className="mt-auto sm:px-6 lg:px-8">
                <div className="bg-gray-50 p-6 sm:rounded-lg sm:p-8">
                  <h2 id="summary-heading" className="sr-only">
                    Order summary
                  </h2>

                  <div className="flow-root">
                    <dl className="-my-4 divide-y divide-gray-200 text-sm">
                      <div className="flex items-center justify-between py-4">
                        <dt className="text-base font-medium text-gray-900">Order total</dt>
                        <dd className="text-base font-medium text-gray-900">{total}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>

              <div className="mt-8 flex px-4 sm:px-6 lg:px-8">
                <div className="">
                  <Link
                    href="/cart"
                    className="font-medium text-primary-600 hover:text-primary-500 underline flex"
                    onClick={() => setOpenSidebar(false)}
                  >
                    <>
                      <div className="hidden sm:flex">
                        <span>
                          <ShoppingCartIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                        </span>
                        <span>View Full Shopping Cart</span>
                      </div>
                      <div className="flex sm:hidden">
                        <span>
                          <ShoppingCartIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                        </span>
                        <span>Full Cart</span>
                      </div>
                    </>
                  </Link>
                </div>
                <div className="flex-1"></div>
                <div className="justify-end">
                  {checkoutUrl && (
                    <Link
                      href={checkoutUrl}
                      className="rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-xs hover:bg-primary-700 focus:outline-hidden focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                      Continue to Payment
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
