/** @jsxImportSource theme-ui */

import React, { FC, useState, useEffect, useCallback, useRef } from "react"
import { useRouter } from "next/router"
import { LoadingDots } from "@components/ui"
import { ProductGrid } from "blocks/ProductGrid/ProductGrid"
import { Button, jsx, Input, Label } from "theme-ui"
import { searchProducts } from "@lib/swell/storefront-data-hooks/src/api/operations-swell"
import { ExpandModal } from "react-spring-modal"
import { throttle } from "lodash"
import "react-spring-modal/styles.css"
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid"

interface Props {
  className?: string
  id?: string
}

const Searchbar: FC<Props> = () => {
  const router = useRouter()
  const { q } = router.query
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsOpen(false)
  }, [router.asPath.split("?")[0]])

  return (
    <React.Fragment>
      {/* <ExpandModal
        transitionConfig={{}}
        contentTransition={{}}
        overlayProps={{
          style: {
            maxWidth: 1920,
            left: "50%",
            transform: "translateX(-50%)",
            overflow: "auto",
            top: (buttonRef.current?.getBoundingClientRect().bottom || 0) + 15,
          },
        }}
        isOpen={isOpen}
      >
        <SearchModalContent
          initialSearch={q && String(q)}
          onSearch={(term: string) => {
            const op = q ? "replace" : "push"
            router[op]({
              pathname: router.asPath.split("?")[0],
              query: {
                q: term,
              },
            })
          }}
        />
      </ExpandModal> */}

      <div className="w-full max-w-lg lg:max-w-xs">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            name="search"
            className="block w-full rounded-md border border-transparent bg-gray-700 py-2 pl-10 pr-3 leading-5 text-gray-300 placeholder-gray-400 focus:border-white focus:bg-white focus:text-gray-900 focus:outline-hidden focus:ring-white sm:text-sm"
            placeholder="Search"
            type="search"
            onInput={() => setIsOpen(true)}
            onEmptied={() => setIsOpen(false)}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const SearchModalContent = (props: { initialSearch?: string; onSearch: (term: string) => any }) => {
  const [search, setSearch] = useState(props.initialSearch && String(props.initialSearch))
  const [products, setProducts] = useState([] as any[])
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const getProducts = async (searchTerm: string) => {
    setLoading(true)
    const results = await searchProducts(
      String(searchTerm),
      // TODO: pagination
      20,
      0
    )
    setSearch(searchTerm)
    setProducts(results)
    setLoading(false)
    if (searchTerm) {
      props.onSearch(searchTerm)
    }
  }

  useEffect(() => {
    if (search) {
      getProducts(search)
    }
  }, [])

  const throttleSearch = useCallback(throttle(getProducts), [])

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        p: [1, 2],
        width: "100%",
      }}
    >
      <Input
        type="search"
        sx={{ marginBottom: 15 }}
        defaultValue={props.initialSearch}
        placeholder="Search for products..."
        onChange={(event) => throttleSearch(event.target.value)}
      />
      {loading ? (
        <LoadingDots />
      ) : products.length ? (
        <>
          <Label>
            Search Results for &quot;<strong>{search}</strong>&quot;
          </Label>
          <ProductGrid
            cardProps={{
              imgHeight: 540,
              imgWidth: 540,
              imgPriority: false,
            }}
            products={products}
            offset={0}
            limit={products.length}
          ></ProductGrid>
        </>
      ) : (
        <span>
          {search ? (
            <>
              There are no products that match &quot;<strong>{search}</strong>&quot;
            </>
          ) : (
            <> </>
          )}
        </span>
      )}
    </div>
  )
}

export default Searchbar
